// This file extends funcionality of MicroModal to cover all cases of use that iberostar needs but library dont supply

import MicroModal from 'micromodal'

const MODAL_TEMPLATE_ID = 'micromodal-template'

const openedModalIds = {}
const defaultConfig = {
  disableFocus: true,
}

const DATA_MODAL_PLACEHOLDER = 'data-modal-placeholder'
const CLOSE_ANIMATION_TIMEOUT = 500

function moveToBody(modal, modalId) {
  if (modal.parentNode !== document.body) {
    const parent = modal.parentNode
    const placeholder = document.createElement('div')
    placeholder.setAttribute(DATA_MODAL_PLACEHOLDER, modalId)
    parent.appendChild(placeholder)

    document.body.appendChild(modal)
  }
}

function returnToPlaceholder(modal, modalId) {
  if (modal.parentNode === document.body) {
    const placeholder = document.querySelector(`[${DATA_MODAL_PLACEHOLDER}="${modalId}"]`)
    if (placeholder) {
      const parent = placeholder.parentNode

      setTimeout(() => {
        parent.appendChild(modal)
        placeholder.remove()
      }, CLOSE_ANIMATION_TIMEOUT)
    }
  }
}

// Append modal to body before show to ensure correct positioning
const originalShow = MicroModal.show
MicroModal.show = function (modalId, internalConfig = {}) {
  // clone config to avoid original config modification
  const config = { ...defaultConfig, ...internalConfig }

  if (!modalId) {
    // eslint-disable-next-line no-console
    console.warn(`[MicroModal] modal id is required to show modal`)
    return
  }

  const modal = document.querySelector(`#${modalId}`)

  if (!modal) {
    // eslint-disable-next-line no-console
    console.warn(`[MicroModal] modal with id ${modalId} is not in page`)
    return
  }

  moveToBody(modal, modalId)

  const originalOnShow = config?.onShow
  config.onShow = modal => {
    if (!openedModalIds[modalId]) {
      openedModalIds[modalId] = config
    }

    if (originalOnShow) {
      originalOnShow(modal)
    }
  }

  const originalOnClose = config?.onClose
  config.onClose = modal => {
    if (openedModalIds[modalId]) {
      delete openedModalIds[modalId]
    }

    if (Object.keys(openedModalIds).length) {
      const nextModal = Object.keys(openedModalIds).slice(-1)[0]
      originalShow(nextModal, openedModalIds[nextModal])
    }

    returnToPlaceholder(modal, modalId)

    if (originalOnClose) {
      originalOnClose(modal)
    }
  }

  originalShow(modalId, config)
}

// Check if there is any modal opened before call MicroModal.close method
const originalClose = MicroModal.close
MicroModal.close = targetModal => {
  if (!Object.keys(openedModalIds).length) return

  originalClose(targetModal)
}

// Close all opened modals
MicroModal.closeAll = () => {
  Object.keys(openedModalIds).forEach(() => {
    MicroModal.close()
  })
}

// Due to a detected bug in micromodal init, and pull request is still not merged https://github.com/ghosh/Micromodal/pull/313
// That causes error: Invalid attempt to spread non-iterable instance
// This method was created to initialize micromodal giving a specific data attribute in openTrigger
MicroModal.init = function (initConfig) {
  if (!initConfig || (initConfig && !initConfig.openTrigger)) {
    // eslint-disable-next-line no-console
    console.warn(`[MicroModal] openTrigger config param is required to initialize modal with init function`)
    return
  }
  if (!initConfig || !initConfig.openTrigger) return
  const config = { ...defaultConfig, ...initConfig }
  const modalTriggers = document.querySelectorAll(`[${config.openTrigger}]`)

  if (!modalTriggers.length) return

  modalTriggers.forEach(function (trigger) {
    const modalId = trigger.getAttribute(config.openTrigger)
    trigger.addEventListener('click', function () {
      MicroModal.show(modalId, config)
    })
  })
}

// Shows a modal with html passed as param in config.html
// example:
// MicroModal.showHtml({ html: '<h1>Hello!</h1>' })
MicroModal.showHtml = function (config) {
  if (!config || (config && !config.html)) {
    // eslint-disable-next-line no-console
    console.warn(`[MicroModal] insufficient info to render modal, modal content is required`)
    return
  }

  config.modalClass ??= ''

  const randomId = new Date().getTime()
  const modalId = `modal-${randomId}`
  const modalTemplate = document.querySelector(`#${MODAL_TEMPLATE_ID}`)

  if (!modalTemplate) return

  let modalTemplateHtml = modalTemplate.innerHTML

  modalTemplateHtml = modalTemplateHtml
    .replaceAll('{{modal_id}}', modalId)
    .replaceAll('{{modal_class}}', config.modalClass)
    .replaceAll('{{modal_content}}', config.html)

  document.body.insertAdjacentHTML('beforeend', modalTemplateHtml)

  const onClose = config.onClose
  config.onClose = modal => {
    // Call onclose function passed as parameter in config object
    onClose && onClose(modal)
    // Remove dynamic generated modal
    modal.remove()
  }

  MicroModal.show(modalId, config)
}

MicroModal.showIframe = function (config) {
  if (!config || (config && !config.iframe)) {
    // eslint-disable-next-line no-console
    console.warn(`[MicroModal] insufficient info to render modal, iframe url is required`)
    return
  }

  // titleIframe. Boolean
  // Show title to a modal iframe
  const titleIframe = config.titleIframe
    ? `<p class="modal-title t-h2" data-modal-title-iframe>${config.titleIframe}</p>`
    : ''

  const randomId = Math.round(Math.random() * 10000)

  config.html = `
    <div class="modal__iframe-container">
      ${titleIframe}
      <iframe id="modal-video-${randomId}"
        name="modal-video-${randomId}"
        class="modal__iframe ${config.titleIframe ? 'with-title' : ''}"
        frameborder="0"
        vspace="0"
        hspace="0"
        webkitAllowFullScreen
        mozallowfullscreen
        allowFullScreen
        allowtransparency="true"
        src="${config.iframe}">
      </iframe>
    </div>
  `

  config.modalClass = `${config.modalClass || ''} has-iframe`

  MicroModal.showHtml(config)
}

if (typeof window !== 'undefined') {
  window.Modal = MicroModal
}

export default MicroModal
