/* eslint-disable no-console */
/**
 * Get Lazy Data
 *
 * Promise (using fetch polyfilled) that allows
 * a callback to be executed.
 *
 * The callback will receive the resolved data
 * from the last Promise.
 *
 * @params
 *  - A url for the resource.
 *  - Type of the data being requested (html or json, the former by default).
 *  - The callback (optional)
 *  - opts (optional: https://developer.mozilla.org/es/docs/Web/API/Fetch_API/Utilizando_Fetch)
 */

const TYPE_FUNCS = {
  html: 'text',
  json: 'json',
}

function checkTypeValue(type) {
  return TYPE_FUNCS[type] || TYPE_FUNCS['html']
}

export async function getLazyData(url, callback, dataType = 'html', opts = { method: 'GET' }) {
  const dataTypeChecked = checkTypeValue(dataType)

  const response = await fetch(url, opts)
  const data = await response[dataTypeChecked]()

  !response.ok && console.trace(`Fetch failed. Response status: ${response.status}`)
  callback && callback(data, response.ok, response)
}
