export const AKAMAI_DEVICE_TYPES = {
  desktop: 'desktop',
  tablet: 'tablet',
  mobile: 'mobile',
}

export const REGEX_LIB = {
  onlyNumbers: new RegExp('^[0-9]*$'),
  phoneNumber: new RegExp('^[0-9]{5,15}$'),
  emailFormat: new RegExp(/^[^\s@]+@[^\s@]+\.[^\s@]+$/),
  noSpaces: new RegExp('^[a-zA-Z0-9]*$'),
}
