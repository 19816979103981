import { AKAMAI_DEVICE_TYPES } from './constants'

const deviceType = document.getElementsByTagName('html')[0].dataset.deviceType
const isTouchDevice = 'ontouchstart' in window || navigator.maxTouchPoints > 0 || navigator.msMaxTouchPoints > 0
const isDesktop = deviceType === AKAMAI_DEVICE_TYPES.desktop

export const currentDevice = {
  type: deviceType,
  isDesktop,
  isLikeDesktop: deviceType === AKAMAI_DEVICE_TYPES.tablet && window.outerWidth > 1024,
  isLikeMobile: deviceType === AKAMAI_DEVICE_TYPES.tablet && window.outerWidth < 768,
  isTouchDesktop: isDesktop && isTouchDevice,
  isTablet: deviceType === AKAMAI_DEVICE_TYPES.tablet,
  isMobile: deviceType === AKAMAI_DEVICE_TYPES.mobile,
  isTouchDevice,
}
